import { isDomestic } from "../../../../../../../utils/utils";
import { CC_Charges, FET_Fee, FeeTypeIds, Handling_Fee, Segment_Fee } from "../enum";
import {
  ICharterFormSchema,
  ICharterLegSchema,
  ICharterOptionSchema,
  ICharterOtherFeeSchema,
} from "../form/schema";
import { ILeg } from "../interface/ILeg";
import { ILegOptionFee } from "../interface/ILegOption";
import { ITripTaxSurcharge } from "../interface/ITripTaxSurcharge";

export const CalculatePreTaxOfOption = (option: ICharterOptionSchema) => {
  return option.legs
    .map((a: ICharterLegSchema) => {
      return Boolean(a.revenuePreTax) ? Number(a.revenuePreTax) : 0;
    })
    .reduce((a, b) => a + b, 0);
};

export const CalculateSegmentFees = (
  legs: Array<ILeg>,
  surcharge: Array<ITripTaxSurcharge>
) => {
  let fee = 0;
  const surchargeAmountNational = Number(
    GetSegmentFees(surcharge).DOMESTIC?.surchargeAmount
  ); 

  const surchargeAmountInterNational = Number(
    GetSegmentFees(surcharge).INTERNATIONAL?.surchargeAmount
  ); 

  for(let i = 0; i < legs.length; i++) {
    let isInternational = false;
    const leg = legs[i];
    if(!isDomestic(leg?.originAirport?.airportAddress?.country?.id, leg?.destinationAirport?.airportAddress?.country?.id)){
      isInternational = true;
    }
     fee += (leg.numberOfPassengers * (isInternational ? surchargeAmountInterNational : surchargeAmountNational));

  }
  return fee;
};

export const CalculateFETFees = (
  preTaxCost: number,
  handlingFee: number,
  internationalFee: number,
  otherFees: Array<ICharterOtherFeeSchema>,
  fetPercent: number
) => {
  // Calculate the other fees sum
  const otherFeeSum = otherFees
    .filter((a) => a.taxable)
    .map((a) => Number(a.amount))
    .reduce((a, b) => a + b, 0);
  return ((otherFeeSum + preTaxCost + handlingFee + internationalFee) * fetPercent) / 100;
};

export const CalculateAllInCashFees = (
  preTaxCost: number,
  handlingFee: number,
  segmentFee: number,
  fetAmount: number,
  internationalFee: number,
  otherFees: Array<ICharterOtherFeeSchema>,
) => {
  // Calculate the other fees sum
  const otherFeeSum = otherFees
    .map((a) => Number(a.amount))
    .reduce((a, b) => a + b, 0);

  return (otherFeeSum + preTaxCost + handlingFee + internationalFee + fetAmount + segmentFee);
};



export const GetHandlingFees = (surcharges: Array<ITripTaxSurcharge>) => {
  return {
    CHARTER: surcharges.find(
      (surcharge) => surcharge.surchargeName === Handling_Fee.Handling_Fee_Charter
    ),
    JET_CARD: surcharges.find(
      (surcharge) => surcharge.surchargeName === Handling_Fee.Handling_Fee_DOM
    ),
  };
};

export const GetSegmentFees = (surcharges: Array<ITripTaxSurcharge>) => {
  return {
    DOMESTIC: surcharges.find(
      (surcharge) => surcharge.surchargeName === Segment_Fee.Segment_Fee_DOM
    ),
    INTERNATIONAL: surcharges.find(
      (surcharge) => surcharge.surchargeName === Segment_Fee.Segment_Fee_INT
    ),
  };
};

export const GetFETFees = (surcharges: Array<ITripTaxSurcharge>) => {
  return {
    DOMESTIC: surcharges.find(
      (surcharge) => surcharge.surchargeName === FET_Fee.FET
    ),
    INTERNATIONAL: surcharges.find(
      (surcharge) => surcharge.surchargeName === FET_Fee.FET
    ),
  };
};

export const GetCcCharge = (surcharge: Array<ITripTaxSurcharge>) => {
  return surcharge.find(
    (surcharge) => surcharge.surchargeName === CC_Charges.CC_Charges
  )
}

export const sumAmount = (array: number[]) => {
  if (!array || array?.length === 0) {
    return 0
  } else {
    return (
      array?.reduce((total: number, num: number) => {
        return total + num;
      })
    );
  }
};

export const percentAmount = (amount: number, percentage: number) => {
  return amount * (percentage / 100);
};

export const calculateRevenueCost = (value: any, operatorCost: any) => {
  if(operatorCost == 0) {
    return {
      costInDollar: "0", 
      revenuePreTax: "0", 
      costInPercent: "0"
    }
  }
  let costInDollar = Number(value);
  let revenuePreTax = Number((operatorCost + costInDollar).toFixed(2));
  let costInPercent = Number(
    ((costInDollar / operatorCost) * 100).toFixed(2)
  );

  return {costInDollar, revenuePreTax, costInPercent};
}

export const getExistingFee = (fees: ILegOptionFee[]) => {
  const handlingFee = fees.find((fee) => String(fee.id) === FeeTypeIds.Handling_Fee)?.amount ?? 0;
  const segmentFee = fees.find((fee) => String(fee.id) === FeeTypeIds.Segment_Fee)?.amount ?? 0;
  const fetFee = fees.find((fee) => String(fee.id) === FeeTypeIds.FET_Fee)?.amount ?? 0;
  const internationalFee = fees.find((fee) => String(fee.id) === FeeTypeIds.International_Fee)?.amount ?? 0;
  return {
    handlingFee,
    segmentFee, 
    fetFee,
    internationalFee
  }
}